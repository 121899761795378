import { DiscoveryApi, IdentityApi, OAuthApi, FetchApi } from "@backstage/core-plugin-api";
import { ResponseError } from '@backstage/errors';
import { MySpaceApi } from "./MySpaceApiRef";
import { UserSystem, UserTeam } from "./types";

/**
 * Represents a client for the MySpace API.
 * @implements {MySpaceApi}
 */
export class MySpaceClient implements MySpaceApi {

    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
    authApi: OAuthApi;
    fetchApi: FetchApi;

    /**
     * Creates an instance of MySpaceClient.
     *
     * @param {Object} options - The options object.
     * @param {DiscoveryApi} options.discoveryApi - The Discovery API.
     * @param {IdentityApi} options.identityApi - The Identity API.
     * @param {OAuthApi} options.authApi - The OAuth API.
     * @param {FetchApi} options.fetchApi - The Fetch API.
     * @memberof MySpaceClient
     */
    constructor(options: {
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
        authApi: OAuthApi;
        fetchApi: FetchApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
        this.authApi = options.authApi;
        this.fetchApi = options.fetchApi;
    }

    /**
     * Retrieves the teams associated with the current user.
     * @param options - An optional object containing the component reference.
     * @returns A promise that resolves to an array of UserTeam objects.
     */
    public async getUserTeams(options: { componentRef?: string }): Promise<Array<UserTeam>> {
        if (!options.componentRef) {
            return [];
        }
        const urlSegment = `teams/${options.componentRef}`;
        const teams = await this.get<Array<UserTeam>>(urlSegment);
        const teamsOrederedByName = teams ? teams.sort((a, b) => a.name.localeCompare(b.name)) : teams;
        return teamsOrederedByName;
    }

    /**
     * Retrieves the user systems for the specified component references.
     * @param options - The options for retrieving the user systems.
     * @param options.componentRefs - The component references to retrieve the user systems for.
     * @returns An array of user systems.
     */
    public async getUserSystems(options: { componentRefs?: string[] }): Promise<Array<UserSystem>> {
        if (!options.componentRefs || options.componentRefs.length === 0) {
            return [];
        }
        const systems: UserSystem[] = [];
        for (const componentRef of options.componentRefs || []) {
            const urlSegment = `systems/${componentRef}`;
            const teamSystem = await this.get<Array<UserSystem>>(urlSegment);
            systems.push(...teamSystem);
        }
        const systemsOrederedByName = systems ? systems.sort((a, b) => a.name.localeCompare(b.name)) : systems;
        return systemsOrederedByName;
    }

    /**
     * Retrieves the user picture for the specified user object id.
     * @param options - The options for retrieving the user picture.
     * @param options.userObjectId - The user object id to retrieve the user picture for.
     * @returns The user picture.
     */
    public async getUserPicture(options: { userObjectId?: string }): Promise<string> {
        if (!options.userObjectId) {
            return '';
        }

        const url = new URL(
            `/v1.0/users/${options.userObjectId}/photo/$value`,
            'https://graph.microsoft.com');

        var headers: any = {};

        const token = await this.authApi.getAccessToken();

        if (!token) {
            return '';
        }

        headers.Authorization = `Bearer ${token}`;

        const response = await this.fetchApi.fetch(url.toString(), {
            headers: headers,
        });

        if (!response.ok) {
            return '';
        }

        const base64 = response.blob().then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((res) => {
                reader.onloadend = () => {
                    res(reader.result);
                }
            })
        });

        return base64 as Promise<string>;
    }

    /**
     * Makes a GET request to the specified path and returns the response as a Promise of type T.
     * @param path The path to make the GET request to.
     * @returns A Promise of type T representing the response from the GET request.
     */
    private async get<T>(path: string): Promise<T> {
        const baseUrl = `${await this.discoveryApi.getBaseUrl('my-space')}`;
        const url = new URL(`${baseUrl}/${path}`);
        const { token } = await this.identityApi.getCredentials();
        const response = await fetch(url.toString(), {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return response.json() as Promise<T>;
    }
}