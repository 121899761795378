export type COST_CHART_DATA = {
    date: string;
    value: number;
    key?: string;
    amount: string;
    compareValue: number;
    compareAmount: string;
};

export type COST_CHART_DATA_STACKED = {
    [key: string]: string | number;
};

export enum COMPUTE_BY {
    day,
    month,
    year,
    none
};

export type AzureCostPeriod = {
    startDate: string;
    endDate: string;
};

export type AzureTags = {
    CostCenter: Array<string>;
};

export type MonthSelector = {
    label: string;
    value: string;
};

export type YearSelector = {
    label: string;
    value: string;
};

export enum COST_TREND {
    up,
    down,
    none
}

export type CostDiff = {
    diff?: string;
    diffPercent?: string;
    diffTrend: COST_TREND;
};

export const COST_CHART_DATA_STACKED_COLORS = [
    "#a05195",
    "#d45087",
    "#f95d6a",
    "#ff7c43",
    "#ffa600",
    "#fd7f6f",
    "#7eb0d5",
    "#b2e061",
    "#bd7ebe",
    "#ffb55a",
    "#ffee65",
    "#beb9db",
    "#fdcce5",
    "#8bd3c7",
    "#f7a35c",
    "#8085e9",
];
