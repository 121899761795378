import { AzureCostApi, CostReport } from "./AzureCostApiRef";
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export class AzureCostClient implements AzureCostApi {

    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;

    constructor(options: {
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
    }

    public async getCostReport(options: { componentRef?: string }): Promise<Array<CostReport>> {
        const urlSegment = `report/${options.componentRef}`;
        const reports = await this.get<Array<CostReport>>(urlSegment);
        return reports;
    }

    private async get<T>(path: string): Promise<T> {
        const baseUrl = `${await this.discoveryApi.getBaseUrl('azure-cost')}`;
        const url = new URL(`${baseUrl}/${path}`);
        const { token } = await this.identityApi.getCredentials();
        const response = await fetch(url.toString(), {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return response.json() as Promise<T>;
    }

}