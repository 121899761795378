import {
  createTheme,
  BackstageTheme,
  palettes,
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
} from '@backstage/theme';

import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';

import { Theme, alpha } from '@material-ui/core/styles';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#002C52',
  };
});

const baseTheme = createTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#0052CC',
      },
      secondary: {
        main: '#FF5C35',
      },
      error: {
        main: '#dc2d40',
      },
      warning: {
        main: '#ff9800',
      },
      info: {
        main: '#5a8ff9',
      },
      success: {
        main: '#4caf50',
      },
      background: {
        default: '#F7F9FE',
        paper: '#ffffff',
      },
      banner: {
        info: '#5a8ff9',
        error: '#dc2d40',
        text: '#002c52',
        link: '#002eaa',
      },
      errorBackground: '#9f0526',
      warningBackground: '#af450c',
      infoBackground: '#003fc0',
      navigation: {
        ...palettes.light.navigation,
        background: '#002c52',
        indicator: '#ff5c35',
        color: '#ffffff',
        submenu: {
          background: '#002c52'
        },
        navItem: {
          hoverBackground: '#002EAA',
        }
      },
    },
    defaultPageTheme: 'home',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    pageTheme: pageThemesFontColorOverride,
  }),
});

const createCustomThemeOverrides = (
  theme: Theme,
): BackstageOverrides & CatalogReactOverrides => {
  return {
    MuiCssBaseline: {
      '@global': {
        div: {
          '&[class^="BackstageSidebar-drawer-"]': {
            borderRight: '1px solid rgba(0, 44, 82, 0.2)'
          },
        },
        a: {
          '&[class^="BackstageSidebarSubmenuItem-selected-"]': {
            backgroundColor: theme.palette.secondary.main
          },
        }
      }
    },
    BackstagePage: {
      root: {
        borderLeft: '0px solid #b3c0cb'
      },
    },
    BackstageHeader: {
      header: {
        borderBottom: '1px solid #002C521F',
        backgroundImage: 'unset',
        boxShadow: 'unset',
        backgroundColor: '#FFFFFF',
        paddingBottom: theme.spacing(3),
        '& button': {
          color: "#002C52"
        }
      },
      title: {
        color: '#002C52'
      },
      subtitle: {
        color: alpha('#002C52', 0.8),
      },
      breadcrumb: {
        color: alpha('#002C52', 0.8),
      },
      breadcrumbTitle: {
        color: alpha('#002C52', 0.8),
      },
      type: {
        color: alpha('#002C52', 0.8),
      },
    },
    BackstageHeaderLabel: {
      label: {
        color: '#002C52',
      },
      value: {
        color: alpha('#002C52', 0.8),
      },
    },
    BackstageHeaderTabs: {
      tabRoot: {
        backgroundColor: theme.palette.background.default
      },
      tabsWrapper: {
        backgroundColor: theme.palette.background.default
      },
      defaultTab: {
        color: '#002c52A3'
      },
      selected: {
        color: theme.palette.navigation.background
      }
    },
    BackstageSidebarDivider: {
      root: {
        color: 'rgba(114, 116, 117, 0.32)',
        width: '100%',
      }
    },
    BackstageSidebarItem: {
      root: {
        textDecoration: 'none',
        "& h6": {
          color: '#ffffff'
        },
      },
      closedItemIcon: {
        color: '#ffffff'
      },
      highlighted: {
        color: theme.palette.secondary.main
      }
    },
    BackstageItemCardHeader: {
      root: {
        backgroundColor: '#F7F8F9',
        backgroundImage: 'unset',
        borderBottom: '1px solid rgba(0, 44, 82, 0.2)',
        '& h4': {
          color: theme.palette.banner.text
        },
        '& h3': {
          color: theme.palette.banner.text
        }
      }
    },
    BackstageTable: {
      root: {
        '&> :first-child': {
          borderBottom: '1px solid #D5D5D5',
          boxShadow: 'none',
        },
        '& th': {
          borderTop: 'none',
          textTransform: 'none !important',
        },
      },
    },
    BackstageOpenedDropdown: {
      icon: {
        '& path': {
          fill: '#FFFFFF',
        },
      },
    },
    BackstageEmptyState: {
      action: {
        '& span[class^="MuiButton-label"]': {
          color: '#ffffff'
        }
      }
    },
    MuiDrawer: {
      paper: {
        "& h3": {
          color: theme.palette.navigation.color
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        textTransform: 'none'
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiSelect: {
      root: {
        '&[aria-expanded]': {
          color: '#002c52',
        },
      },
    },
    MuiSwitch: {
      root: {
        padding: 10,
      },
      switchBase: {
        padding: 12,
      },
      thumb: {
        backgroundColor: '#FFFFFF',
        height: 14,
        width: 14,
      },
      track: {
        borderRadius: 9,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: theme.palette.secondary.main
      },
    },
    MuiGrid: {
      root: {
        borderColor: '1px solid rgba(0, 44, 82, 0.2)'
      }
    },
    MuiPaper: {
      root: {
        border: '1px solid rgba(0, 44, 82, 0.2)',
        boxShadow: 'unset'
      }
    },
    MuiChip: {
      root: {
        color: '#4d6b86',
        backgroundColor: '#f7f8f9',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#ccd4dc',
        marginLeft: '2px'
      },
      outlined: {
        backgroundColor: '#f7f8f9',
        borderColor: '#ccd4dc'
      }
    },
    MuiTypography: {
      colorPrimary: {
        color: theme.palette.primary.main
      }
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#ffffff',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#f7f7f7',
        },
      }
    },
    MuiListItem: {
      container: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#ffffff',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#f7f7f7',
        }
      },
      selected: {
        "& svg": {
          color: "#cc492a"
        }
      }
    },
    MuiLink: {
      root: {
        color: theme.palette.primary.main,
        "& h6": {
          color: theme.palette.primary.main
        }
      },
      underlineHover: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        outline: 0,
        "& svg": {
          color: theme.palette.primary.main
        }
      }
    },
    CatalogReactUserListPicker: {
      root: {
        background: '#0046fe52'
      }
    },
    MuiBottomNavigationAction: {
      wrapper: {
        "& svg": {
          color: theme.palette.navigation.color
        }
      }
    },
  };
};

export const cegidTheme: BackstageTheme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};