import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { azureCostApiRef, AzureCostClient } from './api';

export const azureCostPlugin = createPlugin({
  id: 'azure-cost',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: azureCostApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new AzureCostClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const AzureCostPage = azureCostPlugin.provide(
  createRoutableExtension({
    name: 'AzureCostPage',
    component: () =>
      import('./components/AzureCostContentPage').then(m => m.AzureCostContentPage),
    mountPoint: rootRouteRef,
  }),
);
