import { createApiRef } from '@backstage/core-plugin-api';

/**
 * Represents a data metric with a timestamp and a numeric value.
 */
export type DataMetric = {
    timeStamp: string;
    value: number;
    total?: number;
    average?: number;
    minimum?: number;
    maximum?: number;
    count?: number;
}

/**
 * Represents a metrics report.
 */
export interface MetricsReport {
    id: string;
    name: string;
    description: string;
    timespan: string;
    interval: string;
    unit: string;
    data: DataMetric[];
    metricId: string;
    namespace: string;
    resourceRegion: string;
}

/**
 * API reference for the Azure Metrics service.
 */
export const azureMetricsApiRef = createApiRef<AzureMetricsApi>({
    id: 'plugin.azure-metrics.service',
});

/**
 * Represents an API for retrieving Azure metrics reports.
 */
export type AzureMetricsApi = {
    getMetricsReports(options: {
        componentRef?: string
    }): Promise<Array<MetricsReport>> | undefined;
}