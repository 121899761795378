import { parse, format, addMonths, isMatch, addYears } from "date-fns";
import { COST_CHART_DATA, AzureCostPeriod, COMPUTE_BY, COST_TREND, CostDiff, COST_CHART_DATA_STACKED } from "./types";
import { COST_DATA } from "./components/AzureCostContentPage";

export const AZURE_COST_TAGS_ANNOTATION = "backstage.io/cost-insights-azure-tags";
export const AZURE_COST_SUBSCRIPTIONS_ANNOTATION = "backstage.io/cost-insights-azure-subscriptions";
export const AZURE_COST_REPORTS_LOCATION = "backstage.io/cost-insights-azure-reports-ref";

const eurosFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    compactDisplay: 'long',
    maximumFractionDigits: 0,
});

export const formatAmount = (value: number) => eurosFormatter.format(Math.round(value));

export const formatDate = (date: string) => {
    if (isMatch(date, 'yyyyMM')) {
        return format(parse(date, 'yyyyMM', new Date()), 'MMMM yyyy')
    }
    if (isMatch(date, 'yyyyMMdd')) {
        return format(parse(date, 'yyyyMMdd', new Date()), 'dd MMMM');
    }
    return date;
}

export const computeCostByMonth = (data: Array<COST_CHART_DATA>) => {

    let result: COST_CHART_DATA[] = [];

    data.forEach((value) => {
        const date = parse(value.date, 'yyyyMMdd', new Date());
        const month = format(date, 'MM');
        const year = format(date, 'yyyy');
        const found = result.find((v) => v.date === `${year}${month}`);
        if (found) {
            found.value += value.value;
            found.amount = formatAmount(found.value);
            found.compareValue += value.compareValue ?? 0;
            found.compareAmount = formatAmount(found.compareValue);
        } else {
            result.push({
                date: `${year}${month}`,
                value: value.value,
                amount: formatAmount(value.value),
                compareValue: value.compareValue ?? 0,
                compareAmount: formatAmount(value.compareValue ?? 0),
                key: value.key ?? ''
            });
        }
    });
    return result;
};

export const computeCostByMonthAndResourceType = (data: Array<COST_CHART_DATA>) => {

    let result: COST_CHART_DATA_STACKED[] = [];

    data.forEach((value) => {
        const date = parse(value.date, 'yyyyMMdd', new Date());
        const month = format(date, 'MM');
        const year = format(date, 'yyyy');
        const found = result.find((v) => v.date === `${year}${month}`);
        const unformatedKey = value.key ?? '';
        const formatedKey = unformatedKey.replace(/[^a-z0-9]/gi, '_').replace('microsoft_', '');
        if (found) {
            const foundValue = found[formatedKey] as number ?? 0;
            const computedValue = foundValue + value.value;
            found[formatedKey] = computedValue;
        } else {
            let obj: COST_CHART_DATA_STACKED = {};
            obj.date = `${year}${month}`;
            obj[formatedKey] = value.value;
            result.push(obj);
        }
    });
    return result;
};

export const prepareData = (aggregation: Array<any>, computeBy: COMPUTE_BY, year: number = new Date().getFullYear()) => {
    let data: Array<COST_CHART_DATA> = [];

    const pushData = (key: string, valueAsNumber: number, k?: string) => {
        const date = parse(key, 'yyyyMMdd', new Date());
        if (year === -1 || date.getFullYear() === year) {
            data.push({
                date: key,
                value: valueAsNumber,
                key: k,
                amount: formatAmount(valueAsNumber),
                compareValue: 0,
                compareAmount: formatAmount(0)
            });
        }
    };

    Object.entries(aggregation).forEach(([key, value]) => {
        if (typeof value === 'object') {
            Object.entries(value).forEach(([k, v]) => {
                const valueAsNumber: number = parseFloat(Number(v).toFixed(2));
                pushData(key, valueAsNumber, k);
            });
        } else {
            const valueAsNumber: number = parseFloat(Number(value).toFixed(2));
            pushData(key, valueAsNumber);
        }
    });

    if (computeBy === COMPUTE_BY.day) {
        data = filterDataFromMonth(data);
    }

    if (computeBy === COMPUTE_BY.month) {
        data = computeCostByMonth(data);
    }

    return data;
};

export const groupDataBy = (aggregation: Array<COST_DATA>) => {

    return aggregation.reduce((accu: any, curr) => {
        const date = parse(curr.date, 'yyyyMMdd', new Date());
        const year = date.getFullYear();
        if (accu[year]) {
            accu[year].push(curr);
        } else {
            accu[year] = [curr];
        }
        return accu;
    }, {});
};

export const filterDataFromMonth = (data: Array<COST_CHART_DATA>, month?: string) => {
    if (!month) {
        return data;
    }
    const filterDate = month ? Number(month) : new Date().getMonth();
    return data?.filter((value) => {
        const date = parse(value.date, 'yyyyMMdd', new Date());
        return (date.getMonth() + 1) === filterDate;
    });
};

export const filterDataFromYear = (groupedData: any, year: string): Array<COST_CHART_DATA> => {
    let composedData: Array<COST_CHART_DATA> = [];
    let data: Array<COST_CHART_DATA> = [];
    let pastYearData: Array<COST_CHART_DATA> = [];

    Object.keys(groupedData).forEach((key: string) => {
        if (key === year) {
            data = groupedData[key];
        } else {
            pastYearData = groupedData[key];
        }
    });

    if (data.length > 0 && pastYearData.length > 0) {
        const pastYearDataByMonth = new Map(pastYearData.map(v => {
            const pastDate = parse(v.date, 'yyyyMMdd', new Date());
            const pastMonth = format(pastDate, 'MM');
            return [pastMonth, v];
        }));

        composedData = data.map((value) => {
            const date = parse(value.date, 'yyyyMMdd', new Date());
            const month = format(date, 'MM');
            const compareValue = pastYearDataByMonth.get(month);
            value.compareValue = compareValue?.value ?? 0;
            return value;
        });
    } else {
        composedData = data;
    }
    return composedData;
}

export const computeAndFormatTotalCostByMonth = (data: Array<COST_CHART_DATA>) => {
    let total = 0.0;

    data.forEach(d => {
        total += d.value;
    });

    return formatAmount(total);
};

export const computeAndFormatTotalCostByMonthByResource = (data: Array<COST_CHART_DATA_STACKED>) => {
    let total = 0.0;

    data.forEach(d => {
        Object.entries(d).forEach(([key, value]) => {
            if (key !== 'date') {
                total += value as number;
            }
        });
    });

    return formatAmount(total);
};

export const extractPeriod = (costData: Array<COST_DATA>): AzureCostPeriod => {
    if (costData) {
        let period: AzureCostPeriod = { startDate: '', endDate: '' };
        period = {
            startDate: costData.at(0)?.date ?? '',
            endDate: costData.at(-1)?.date ?? ''
        };

        if (period.startDate) {
            period.startDate = format(parse(period.startDate, 'yyyyMMdd', new Date), 'yyyy-MM');
        }
        if (period.endDate) {
            period.endDate = format(parse(period.endDate, 'yyyyMMdd', new Date), 'yyyy-MM');
        }
        return period;
    }
    return { startDate: '', endDate: '' };
};

export const compareCostWithPreviousMonth = (data: Array<COST_CHART_DATA>, currentMonth: string | undefined): CostDiff => {

    const calculateTotalCost = (data: Array<COST_CHART_DATA>, month: Date) => {
        return data.reduce((total, value) => {
            const date = parse(value.date, 'yyyyMMdd', new Date());
            return date.getMonth() === month.getMonth() ? total + value.value : total;
        }, 0);
    };

    const lastMonthDate = addMonths(parse(currentMonth ?? '', 'MM', new Date()), -1);
    const lastMonthCost = calculateTotalCost(data, lastMonthDate);

    const currentMonthDate = parse(currentMonth ?? '', 'MM', new Date());
    const currentMonthCost = calculateTotalCost(data, currentMonthDate);

    const diff = lastMonthCost === 0 ? 0 : currentMonthCost - lastMonthCost;
    const diffPercent = (diff / lastMonthCost) * 100;
    let diffTrend = COST_TREND.none;

    if (diff > 0) {
        diffTrend = COST_TREND.up;
    }

    if (diff < 0) {
        diffTrend = COST_TREND.down;
    }

    return {
        diff: formatAmount(diff),
        diffPercent: Math.round(diffPercent).toFixed(0),
        diffTrend: diffTrend
    };
};

export const compareCostWithPreviousYear = (groupedData: any, currentYear: string | undefined): CostDiff => {

    const lastYearDate = format(addYears(parse(currentYear ?? '', 'yyyy', new Date()), -1), 'yyyy');

    let lastYearTotalCost = 0.0;
    let currentYearTotalCost = 0.0;

    const calculateTotalCost = (key: string) => {
        const data: Array<COST_CHART_DATA> = groupedData[key];
        return data.reduce((accu, curr) => accu + curr.value, 0);
    };

    Object.keys(groupedData).forEach((key: string) => {
        if (key === lastYearDate) {
            lastYearTotalCost += calculateTotalCost(key);
        }

        if (key === currentYear) {
            currentYearTotalCost += calculateTotalCost(key);
        }
    });

    const diff = lastYearTotalCost === 0 ? 0 : currentYearTotalCost - lastYearTotalCost;
    const diffPercent = (diff / lastYearTotalCost) * 100;
    let diffTrend = COST_TREND.none;

    if (diff > 0) {
        diffTrend = COST_TREND.up;
    }

    if (diff < 0) {
        diffTrend = COST_TREND.down;
    }

    return {
        diff: formatAmount(diff),
        diffPercent: Math.round(diffPercent).toFixed(0),
        diffTrend: diffTrend
    };
};

export const getSubscriptionUri = (subscriptions: Array<string> | undefined) =>
    subscriptions ? `https://portal.azure.com/#@cegidgroup.onmicrosoft.com/resource/subscriptions/${subscriptions[0]}/overview` : undefined;

export const buildHeadersForCsvExport = (): string[] => {
    return ['Date', 'Cost'];
};

export const buildDataForCvsExport = (data: COST_CHART_DATA[]) => {
    let result: any[] = [];

    data.forEach((item) => {
        const obj: any = {};
        obj['Date'] = item.date;
        obj['Cost'] = item.amount;
        result.push(obj);
    });

    return result;
};

export const getResourceCategory = (resource: string) => {
    return resource ? resource.split('_')[0] : '';
};