import { ProjectPackagesApi, DotNetProjectDefinition, NodeProjectDefinition } from "./ProjectPackagesApiRef";
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';


/**
 * Represents a client for interacting with the Project Packages API.
 */
export class ProjectPackagesClient implements ProjectPackagesApi {

    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;

    /**
     * Creates a new instance of the ProjectPackagesClient class.
     * @constructor
     * @param {Object} options - The options object.
     * @param {DiscoveryApi} options.discoveryApi - The DiscoveryApi instance to use.
     * @param {IdentityApi} options.identityApi - The IdentityApi instance to use.
     */
    constructor(options: {
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
    }

    /**
     * Retrieves an array of DotNetProjectDefinition objects from the server.
     * @param options An object containing optional parameters:
     *  - componentRef: A string representing the component reference.
     *  - pattern: A string representing the pattern.
     * @returns A Promise that resolves to an array of DotNetProjectDefinition objects.
     */
    public async getDotNetProjectsDefinition(options: { componentRef?: string, pattern?: string }): Promise<Array<DotNetProjectDefinition>> {
        const urlSegment = `dotnet-parse/${options.componentRef}`;
        const projects = await this.get<Array<DotNetProjectDefinition>>(urlSegment);
        return projects;
    }

    /**
     * Retrieves an array of Node.js project definitions from the server.
     * @param options An object containing optional parameters:
     *   - componentRef: A string representing the component reference.
     *   - pattern: A string representing the pattern to match.
     * @returns A Promise that resolves with an array of NodeProjectDefinition objects.
     */
    public async getNodeProjectsDefinition(options: { componentRef?: string, pattern?: string }): Promise<Array<NodeProjectDefinition>> {
        const urlSegment = `node-parse/${options.componentRef}`;
        const projects = await this.get<Array<NodeProjectDefinition>>(urlSegment);
        return projects;
    }

    /**
     * Sends a GET request to the specified path and returns the response as a Promise.
     * @param path The path to send the GET request to.
     * @returns A Promise that resolves with the response data.
     */
    private async get<T>(path: string): Promise<T> {
        const baseUrl = `${await this.discoveryApi.getBaseUrl('project-packages')}`;
        const url = new URL(`${baseUrl}/${path}`);
        const { token } = await this.identityApi.getCredentials();
        const response = await fetch(url.toString(), {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return response.json() as Promise<T>;
    }
}