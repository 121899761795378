export type AzureMetricsTimeSerieCompare = {
    id?: string;
    name?: string;
    unit?: string;
    data: AzureMetricsDataCompare[];
};

export type AzureMetricsDataCompare = {
    timeStamp: string;
    valueReference: number;
    valueCompare?: number;
};

export enum AzureMetricUnit {
    Count = 0,
    Duration = 1,
    Bytes = 2,
    Percent = 3
};

export enum MetricColorMetricCategory {
    Availability = '#b2e061',
    Performance = '#beb9db',
    Resource = '#bd7ebe',
    Usage = '#7eb0d5',
    Errors = '#fd7f6f',
    Storage = '#8bd3c7'
};

export const colorByMetricName: any = {
    'Requests': MetricColorMetricCategory.Usage,
    'Http5xx': MetricColorMetricCategory.Errors,
    'Http4xx': MetricColorMetricCategory.Errors,
    'HttpResponseTime': MetricColorMetricCategory.Performance,
    'HealthCheckStatus': MetricColorMetricCategory.Availability,
    'Availability': MetricColorMetricCategory.Availability,
    'Transactions': MetricColorMetricCategory.Usage,
    'Ingress': MetricColorMetricCategory.Resource,
    'Egress': MetricColorMetricCategory.Resource,
    'cpu_percent': MetricColorMetricCategory.Resource,
    'storage': MetricColorMetricCategory.Storage,
    'connection_failed': MetricColorMetricCategory.Errors,
    'deadlock': MetricColorMetricCategory.Errors,
    'percentProcessorTime': MetricColorMetricCategory.Performance,
    'allusedmemory': MetricColorMetricCategory.Resource,
    'operationsPerSecond': MetricColorMetricCategory.Usage,
    'errors': MetricColorMetricCategory.Errors,
    'IncomingMessages': MetricColorMetricCategory.Usage,
    'OutgoingMessages': MetricColorMetricCategory.Usage,
    'Size': MetricColorMetricCategory.Storage,
    'ServerErrors': MetricColorMetricCategory.Errors,
    'ActiveConnections': MetricColorMetricCategory.Usage,
    'DeadletteredMessages': MetricColorMetricCategory.Usage,
    'DataUsage': MetricColorMetricCategory.Resource,
    'DocumentCount': MetricColorMetricCategory.Resource,
    'IndexUsage': MetricColorMetricCategory.Resource,
    'ServiceAvailability': MetricColorMetricCategory.Availability,
    'MessageCount': MetricColorMetricCategory.Usage,
    'InboundTraffic': MetricColorMetricCategory.Usage,
    'OutboundTraffic': MetricColorMetricCategory.Usage,
    'SystemErrors': MetricColorMetricCategory.Errors,
    'ConnectionCount': MetricColorMetricCategory.Usage,
    'CpuPercentage': MetricColorMetricCategory.Resource,
    'MemoryPercentage': MetricColorMetricCategory.Resource,
    'BytesReceived': MetricColorMetricCategory.Usage,
    'BytesSent': MetricColorMetricCategory.Usage,
    'HttpQueueLength': MetricColorMetricCategory.Performance,
};

export const colorForComparaison = '#ffb55a';

export type SelectData = {
    label: string;
    value: string;
};