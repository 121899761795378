import { createApiRef } from '@backstage/core-plugin-api';

/**
 * Represents a package definition, which includes the name and version of a package.
 */
export interface PackageDefinition {
    name: string;
    version: string;
}

/**
 * Represents a project definition, which includes an ID, file name, target framework, Azure Function version, and a list of package definitions.
 */
export interface DotNetProjectDefinition {
    id: string;
    fileName: string;
    targetFramework?: string;
    azureFunctionVersion?: string;
    packages: PackageDefinition[];
}

/**
 * Represents a Node.js project definition.
 */
export interface NodeProjectDefinition {
    id: string;
    fileName: string;
    version: string;
    dependencies: PackageDefinition[];
}

export const projectPackagesApiRef = createApiRef<ProjectPackagesApi>({
    id: 'plugin.project-packages.service',
});

/**
 * Represents the API for managing project packages.
 */
export type ProjectPackagesApi = {
    /**
     * Retrieves the definitions of .NET projects.
     * @param options - The options to use when retrieving the definitions.
     * @returns A promise that resolves to an array of .NET project definitions, or undefined if an error occurred.
     */
    getDotNetProjectsDefinition(options: {
        componentRef?: string,
        pattern?: string
    }): Promise<Array<DotNetProjectDefinition>> | undefined;

    /**
     * Retrieves the definitions of Node.js projects.
     * @param options - The options to use when retrieving the definitions.
     * @returns A promise that resolves to an array of Node.js project definitions, or undefined if an error occurred.
     */
    getNodeProjectsDefinition(options: {
        componentRef?: string,
        pattern?: string
    }): Promise<Array<NodeProjectDefinition>> | undefined;
}