import { Progress } from '@backstage/core-components';
import React, { Suspense } from 'react';

const LazyOpenApiDefinition = React.lazy(() =>
    import('./CustomOpenApiDefinition').then(m => ({
        default: m.CustomOpenApiDefinition,
    })),
);

export type CustomOpenApiDefinitionWidgetProps = {
    definition: string;
    oauth: {
        clientId: string;
        scopes: string[]
    }
    requestInterceptor?: (req: any) => any | Promise<any>;
};

export const CustomOpenApiDefinitionWidget = (
    props: CustomOpenApiDefinitionWidgetProps,
) => {
    return (
        <Suspense fallback={<Progress />}>
            <LazyOpenApiDefinition {...props} />
        </Suspense>
    );
};