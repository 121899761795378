import { createPlugin, createRoutableExtension, createApiFactory, discoveryApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { azureMetricsApiRef, AzureMetricsClient } from './api';
import { rootRouteRef } from './routes';

export const azureMetricsPlugin = createPlugin({
  id: 'azure-metrics',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: azureMetricsApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new AzureMetricsClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const AzureMetricsPage = azureMetricsPlugin.provide(
  createRoutableExtension({
    name: 'AzureMetricsPage',
    component: () =>
      import('./components/AzureMetricsContentPage').then(m => m.AzureMetricsContentPage),
    mountPoint: rootRouteRef,
  }),
);
