import { createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { projectPackagesApiRef, ProjectPackagesClient } from './api';

export const projectPackagesPlugin = createPlugin({
  id: 'project-packages',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: projectPackagesApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new ProjectPackagesClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const ProjectPackagesPage = projectPackagesPlugin.provide(
  createRoutableExtension({
    name: 'ProjectPackagesPage',
    component: () =>
      import('./components/ProjectPackagesPage').then(m => m.ProjectPackagesPage),
    mountPoint: rootRouteRef,
  }),
);

export const ProjectPackagesCard = projectPackagesPlugin.provide(
  createRoutableExtension({
    name: 'ProjectPackagesCard',
    component: () =>
      import('./components/ProjectPackagesCard').then(m => m.ProjectPackagesCard),
    mountPoint: rootRouteRef,
  }),
);
