import { createApiRef } from '@backstage/core-plugin-api';
import { UserSystem, UserTeam } from './types';

/**
 * Reference to the MySpace API.
 */
export const mySpaceApiRef = createApiRef<MySpaceApi>({
    id: 'plugin.my-space.service',
});

/**
 * Represents the API for interacting with MySpace.
 */
export type MySpaceApi = {
    getUserTeams(options: {
        componentRef?: string
    }): Promise<Array<UserTeam>> | undefined;
    getUserSystems(options: {
        componentRefs?: string[]
    }): Promise<Array<UserSystem>> | undefined;
    getUserPicture(options: {
        userObjectId?: string
    }): Promise<string> | undefined;
}