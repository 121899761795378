import React from 'react';

import {
    HomePageStarredEntities,
    CustomHomepageGrid,
} from '@backstage/plugin-home';

import { MicrosoftCalendarCard } from '@backstage-community/plugin-microsoft-calendar';
import { HomePageSearchBar } from '@backstage/plugin-search';

import { makeStyles } from '@material-ui/core';

import { CegidWelcome, CegidGetStarted, CegidNews } from '@internal/plugin-cegid-home';

const defaultConfig = [
    {
        component: 'HomePageSearchBar',
        x: 0.5,
        y: 2,
        width: 11,
        height: 5,
    },
    {
        component: 'CegidWelcome',
        x: 0.5,
        y: 4,
        width: 11,
        height: 5,
    },
    {
        component: 'CegidNews',
        x: 0.5,
        y: 10,
        width: 5.5,
        height: 32,
    },
    {
        component: 'CegidGetStarted',
        x: 6,
        y: 10,
        width: 5.5,
        height: 48,
    },
    {
        component: 'HomePageStarredEntities',
        x: 0.5,
        y: 4,
        width: 5.5,
        height: 16,
    }
];

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
        borderStyle: 'none'
    }
}));

export const HomePage = () => {

    const classes = useStyles();

    return (
        <CustomHomepageGrid config={defaultConfig} rowHeight={10}>
            <HomePageSearchBar InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline } }} />
            <CegidWelcome />
            <CegidGetStarted />
            <CegidNews />
            <HomePageStarredEntities title='Favorite Entities' />
            <MicrosoftCalendarCard />
        </CustomHomepageGrid>
    );
}