import { createComponentExtension, createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { createCardExtension } from '@backstage/plugin-home-react';

import { rootRouteRef } from './routes';

export const cegidHomePlugin = createPlugin({
  id: 'cegid-home',
  apis: [
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const CegidHomePage = cegidHomePlugin.provide(
  createRoutableExtension({
    name: 'CegidHomePage',
    component: () =>
      import('./components/ExampleComponent').then(m => m.ExampleComponent),
    mountPoint: rootRouteRef,
  }),
);

export const CegidWelcome = cegidHomePlugin.provide(
  createComponentExtension(
    {
      name: 'CegidWelcome',
      component: {
        lazy: () => import('./components/CegidWelcome').then(m => m.Content),
      },
    })
);

export const CegidGetStarted: any = cegidHomePlugin.provide(
  createCardExtension({
    name: 'CegidGetStarted',
    title: 'Overview',
    description: 'Get started with Cegid',
    components: () => import('./components/CegidGetStarted'),
    layout: {
      height: { minRows: 4 },
      width: { minColumns: 3 },
    }
  }),
);

export const CegidTools: any = cegidHomePlugin.provide(
  createCardExtension({
    name: 'CegidTools',
    title: 'Dev Tools',
    description: 'Cegid Tools',
    components: () => import('./components/CegidTools'),
    layout: {
      height: { minRows: 4 },
      width: { minColumns: 3 },
    }
  }),
);

export const CegidNews: any = cegidHomePlugin.provide(
  createCardExtension({
    name: 'CegidNews',
    title: 'What\'s new?',
    description: 'Cegid News',
    components: () => import('./components/CegidNews'),
    layout: {
      height: { minRows: 4 },
      width: { minColumns: 3 },
    }
  }),
);


