import { utcToZonedTime } from "date-fns-tz";
import { compactInteger, fileSize } from 'humanize-plus';
import { format, secondsToHours, secondsToMinutes, secondsToMilliseconds } from "date-fns";
import { DataMetric, MetricsReport } from "./api";
import {
    AzureMetricUnit,
    AzureMetricsDataCompare,
    AzureMetricsTimeSerieCompare,
    SelectData
} from "./types";

export const AZURE_METRICS_REPORT_LOCATION = "backstage.io/azure-insights-metrics-report-ref";

const parseMetricId = (metricId: string | undefined): string[] | undefined => {
    if (!metricId) return undefined;
    const splitted = metricId.split('/');
    return splitted;
};

export const getValueFromMetricId = (metricId: string | undefined, search: string): string | undefined => {

    const splitted = parseMetricId(metricId);

    if (!splitted) return undefined;

    if (search === 'sub') {
        return splitted[2];
    }

    if (search === 'rg') {
        return splitted[4];
    }

    if (search === 'res') {
        return splitted[8];
    }

    if (search === 'ns') {
        return `${splitted[6]}/${splitted[7]}`;
    }

    return undefined;
};

export const getMetricValueFromUnit = (metric: DataMetric | undefined): number | undefined => {

    if (metric?.total) {
        return metric?.total;
    }

    if (metric?.maximum) {
        return metric?.maximum;
    }

    if (metric?.minimum) {
        return metric?.minimum;
    }

    if (metric?.average) {
        return metric?.average;
    }

    return 0;
};

export const createTimeSerieBaseOnTwoDate = (report: MetricsReport, dateReference: string | undefined, dateCompare: string | undefined): AzureMetricsTimeSerieCompare | undefined => {

    if (!report.data || report.data.length === 0) return undefined;
    if (!dateReference) return undefined;

    const filteredDataSetReference: DataMetric[] = report.data.filter(d => d.timeStamp.split('T')[0] === dateReference.split('T')[0]);
    const filteredDataSetCompare: DataMetric[] = report.data.filter(d => d.timeStamp.split('T')[0] === dateCompare?.split('T')[0]);

    if (!filteredDataSetReference) return undefined;

    const filteredDataSet: AzureMetricsDataCompare[] = filteredDataSetReference.map(dataReference => {
        if (filteredDataSetCompare) {
            const dataCompare = filteredDataSetCompare.find(d2 => d2.timeStamp.split('T')[1] === dataReference.timeStamp.split('T')[1]);
            return {
                timeStamp: dataReference.timeStamp,
                valueReference: getMetricValueFromUnit(dataReference) ?? 0,
                valueCompare: getMetricValueFromUnit(dataCompare) ?? 0
            }
        } else {
            return {
                timeStamp: dataReference.timeStamp,
                valueReference: getMetricValueFromUnit(dataReference) ?? 0
            }
        }
    });

    return {
        id: report.id,
        name: report.name,
        unit: report.unit,
        data: filteredDataSet
    }
};

export const extractListOfUniqueDateWithoutTime = (report: MetricsReport): SelectData[] => {
    if (!report.data || report.data.length === 0) {
        return [];
    }

    const dateList = report.data.map(({ timeStamp }) => ({
        label: timeStamp.split('T')[0],
        value: timeStamp,
    }));

    const unique = [...new Map(dateList.map(item => [item.label, item])).values()];

    return unique;
};

export const dateFormatter = (value: string) => {
    if (!value) return value;
    const date = utcToZonedTime(value, 'UTC');
    return format(date, 'HH:mm');
};

export const getUnitEnumFromString = (unit: string | undefined) => {

    if (!unit || unit.toLocaleLowerCase() === 'count') {
        return AzureMetricUnit.Count;
    }

    if (unit.toLocaleLowerCase() === 'seconds') {
        return AzureMetricUnit.Duration;
    }

    if (unit.toLocaleLowerCase() === 'bytes') {
        return AzureMetricUnit.Bytes;
    }

    if (unit.toLocaleLowerCase() === 'percent') {
        return AzureMetricUnit.Percent;
    }

    return AzureMetricUnit.Count;
};

export const getUnitLabelFromString = (unit: string | undefined) => {

    if (!unit || unit.toLocaleLowerCase() === 'count') {
        return 'Count';
    }

    if (unit.toLocaleLowerCase() === 'seconds') {
        return 'Duration';
    }

    if (unit.toLocaleLowerCase() === 'bytes') {
        return 'Bytes';
    }

    if (unit.toLocaleLowerCase() === 'percent') {
        return 'Percent';
    }

    return 'Count';
};

export const formatDurationMetricValueToBeReadable = (value: number) => {

    if (value === 0) return '0';

    if (value >= 3600) {
        return `${secondsToHours(value)}h`;
    }

    if (value >= 60) {
        return `${secondsToMinutes(value)}m`;
    }

    return `${secondsToMilliseconds(value)}ms`;
};

export const formatCountMetricValueToBeReadable = (value: number) => {
    if (!value) return '0';
    return compactInteger(value, 1);
};

export const formatBytesMetricValueToBeReadable = (value: number) => {

    if (value === 0) return '0';

    return fileSize(value, 1);
};

export const formatPercentMetricValueToBeReadable = (value: number) => {

    if (value === 0) return '0';

    return `${value}%`;
};

export const getReportFromId = (reports: MetricsReport[], id: string) => {
    return reports.find(r => r.id.toLocaleLowerCase() === id.toLocaleLowerCase());
};

export const getErrorsRatioInTotalRequests = (requestsReport: MetricsReport | undefined, errorsReport: MetricsReport | undefined) => {

    if (!requestsReport || !errorsReport) return 0;

    const totalRequests = requestsReport.data?.reduce((acc, curr) => {
        return acc + curr.value;
    }, 0);

    const totalErrors = errorsReport.data?.reduce((acc, curr) => {
        return acc + curr.value;
    }, 0);

    if (!totalRequests || !totalErrors) return 0;

    return (totalErrors / totalRequests);
};

export const tooltipFormatDate = (value: string, interval?: string) => {
    if (!value) return value;
    const date = utcToZonedTime(value, 'UTC');
    if (interval === 'PT1H') {
        return format(date, 'dd/MM/yyyy HH:mm');
    } else {
        return format(date, 'dd/MM/yyyy');
    }
};

export const formatDateForDisplay = (value: string) => {
    if (!value) return value;
    const date = utcToZonedTime(value, 'UTC');
    return format(date, 'yyyy-MM-dd');
}

export const tooltipFormatValue = (value: number, unit: AzureMetricUnit) => {
    if (unit === AzureMetricUnit.Duration) {
        return formatDurationMetricValueToBeReadable(value);
    }
    if (unit === AzureMetricUnit.Bytes) {
        return formatBytesMetricValueToBeReadable(value);
    }
    if (unit === AzureMetricUnit.Percent) {
        return formatPercentMetricValueToBeReadable(value);
    }
    return formatCountMetricValueToBeReadable(value);
};

export const hasStatsPanel = (resourceProvider: string | undefined) => {
    if (!resourceProvider) return false;
    return resourceProvider.toLocaleLowerCase() === 'microsoft.web/sites';
};

export const getPeriodFromTimespan = (timespan: string | undefined) => {
    if (!timespan) return undefined;
    const splitted = timespan.split('/');
    const startDate = utcToZonedTime(splitted[0], 'UTC');
    const endDate = utcToZonedTime(splitted[1], 'UTC');
    return { startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd') };
};

export const buildAzureResourceUriForATenant = (subscriptionId: string | undefined, resourceGroup: string | undefined, resourceProvider: string | undefined, resourceName: string | undefined) => {
    const rootUri = 'https://portal.azure.com/#@cegidgroup.onmicrosoft.com/resource';
    if (!subscriptionId || !resourceGroup || !resourceProvider || !resourceName) return undefined;
    return `${rootUri}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroup}/providers/${resourceProvider}/${resourceName}`;
};

export const buildHeadersForCsvExport = (reports: MetricsReport[]): string[] => {
    return ['Date', ...reports.map(report => report.name)];
};

export const buildDataForCvsExport = (reports: MetricsReport[], date: string | undefined) => {
    if (!date) return [];
    let data: any[] = [];
    reports.forEach(report => {
        const filteredDataSet: DataMetric[] = report.data?.filter(d => d.timeStamp.split('T')[0] === date?.split('T')[0]) ?? [];
        filteredDataSet.forEach(d => {
            const obj: any = {};
            obj['Date'] = d.timeStamp;
            obj[report.name] = getMetricValueFromUnit(d);
            if (data.length === 0) {
                data.push(obj);
            } else {
                const index = data.findIndex(d => d.Date === obj.Date);
                if (index === -1) {
                    data.push(obj);
                } else {
                    data[index][report.name] = obj[report.name];
                }
            }
        });
        return data;
    });
    return data;
};

