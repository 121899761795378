import { createApiRef } from '@backstage/core-plugin-api';

export type Aggregate = {
    date: string;
    amount: number;
}

export interface CostReport {
    name: string;
    description: string;
    date: string;
    subscriptionName: string;
    currency: string;
    sumAll: number;
    isKnown: boolean;
    aggregation: any;
}

export const azureCostApiRef = createApiRef<AzureCostApi>({
    id: 'plugin.azure-cost.service',
});

export type AzureCostApi = {
    getCostReport(options: {
        componentRef?: string
    }): Promise<Array<CostReport>> | undefined;
}