import React from 'react';
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  analyticsApiRef,
  configApiRef,
  createApiFactory,
  fetchApiRef,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';
import {
  MicrosoftCalendarApiClient,
  microsoftCalendarApiRef,
} from '@backstage-community/plugin-microsoft-calendar';
import { TechRadarCegidClient } from './lib/TechRadarCegidClient';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';
import { ApiDefinitionWidget, apiDocsConfigRef, defaultDefinitionWidgets } from '@backstage/plugin-api-docs';
import { ApiEntity } from '@backstage/catalog-model';
import {
  CustomOpenApiDefinitionWidget,
  extractOpenApiClientIdAnnotation,
  extractOpenApiScopesAnnotation,
  hasCustomAnnotations
} from './components/api-docs';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: microsoftCalendarApiRef,
    deps: { authApi: microsoftAuthApiRef, fetchApi: fetchApiRef },
    factory: deps => new MicrosoftCalendarApiClient(deps),
  }),
  createApiFactory(
    techRadarApiRef,
    new TechRadarCegidClient(),
  ),
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
      }),
  }),
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      const definitionWidgets = defaultDefinitionWidgets();
      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          const isVerify = hasCustomAnnotations(apiEntity);
          if (isVerify) {
            return {
              type: 'openapi',
              title: 'OpenAPI',
              rawLanguage: 'yaml',
              component: definition => <CustomOpenApiDefinitionWidget definition={definition} oauth={
                {
                  clientId: extractOpenApiClientIdAnnotation(apiEntity),
                  scopes: extractOpenApiScopesAnnotation(apiEntity),
                }
              } />,
            } as ApiDefinitionWidget;
          }
          // fallback to the defaults
          return definitionWidgets.find(d => d.type === apiEntity.spec.type);
        },
      };
    },
  }),
];
