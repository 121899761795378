import { ApiEntity } from "@backstage/catalog-model";

const clientIdAnnotation = "cegid.com/openapi-client-id";
const scopesAnnotation = "cegid.com/openapi-scopes";

export const hasCustomAnnotations = (apiEntity: ApiEntity) => {
    return apiEntity.metadata.annotations?.[clientIdAnnotation] !== undefined ||
        apiEntity.metadata.annotations?.[scopesAnnotation] !== undefined;
}

export const extractOpenApiClientIdAnnotation = (apiEntity: ApiEntity) => {
    return apiEntity.metadata.annotations?.[clientIdAnnotation] ?? "";
}

export const extractOpenApiScopesAnnotation = (apiEntity: ApiEntity): string[] => {
    const scopes: string | undefined = apiEntity.metadata.annotations?.[scopesAnnotation];
    return scopes ? scopes.split(" ") : [];
}