import React, { PropsWithChildren } from 'react';
import { makeStyles, styled } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import ExploreIcon from '@material-ui/icons/Explore';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import BuildIcon from '@material-ui/icons/Build';
import LayersIcon from '@material-ui/icons/Layers';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FaceRounded from '@material-ui/icons/FaceRounded';

import { useApp } from '@backstage/core-plugin-api';

import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import GroupIcon from '@material-ui/icons/People';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const SidebarDivider = styled('hr')(({ theme }) => ({
  height: 1,
  width: '100%',
  background: 'rgba(114, 116, 117, 0.32)',
  border: 'none',
  margin: theme.spacing(1.2, 0),
}));

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/home" text="Home" />
        <MyGroupsSidebarItem
          singularTitle="My Group"
          pluralTitle="My Groups"
          icon={GroupIcon}
        />
        <SidebarItem icon={FaceRounded} to='/my-space' text='My Space' />
        <SidebarItem icon={LayersIcon} to="catalog" text="Catalog">
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem
              title="Domains"
              to="catalog?filters[kind]=domain"
              icon={useApp().getSystemIcon('kind:domain')}
            />
            <SidebarSubmenuItem
              title="Systems"
              to="catalog?filters[kind]=system"
              icon={useApp().getSystemIcon('kind:system')}
            />
            <SidebarSubmenuItem
              title="Components"
              to="catalog?filters[kind]=component"
              icon={useApp().getSystemIcon('kind:component')}
            />
            <SidebarSubmenuItem
              title="APIs"
              to="catalog?filters[kind]=api"
              icon={useApp().getSystemIcon('kind:api')}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Resources"
              to="catalog?filters[kind]=resource"
              icon={useApp().getSystemIcon('kind:resource')}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Groups"
              to="catalog?filters[kind]=group"
              icon={useApp().getSystemIcon('kind:group')}
            />
            <SidebarSubmenuItem
              title="Users"
              to="catalog?filters[kind]=user"
              icon={useApp().getSystemIcon('kind:user')}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={ExploreIcon} to="explore" text="Explore" />
        <SidebarItem icon={VerifiedUserIcon} to="entity-validation" text="Validator" />
        {/*<SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />*/}
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar">
            <SidebarSubmenu title="Tech Radar">
              <SidebarSubmenuItem title="Dev" subtitle='Development tools' to="tech-radar/dev" icon={useApp().getSystemIcon('dev')} />
              <SidebarSubmenuItem title="Test" subtitle='Test tools' to="tech-radar/test" icon={useApp().getSystemIcon('test')} />
              <SidebarSubmenuItem title="ALM" subtitle='ALM tools' to="tech-radar/alm" icon={useApp().getSystemIcon('alm')} />
              <SidebarDivider />
              <SidebarSubmenuItem title="Ops" subtitle='Ops tools' to="tech-radar/ops" icon={useApp().getSystemIcon('ops')} />
              <SidebarSubmenuItem title="Security" subtitle='Security tools' to="tech-radar/sec" icon={useApp().getSystemIcon('sec')} />
              <SidebarDivider />
              <SidebarSubmenuItem title="Components" subtitle='External commponents' to="tech-radar/comp" icon={useApp().getSystemIcon('comp')} />
              <SidebarSubmenuItem title="Softwares" subtitle='External softwares' to="tech-radar/soft" icon={useApp().getSystemIcon('soft')} />
            </SidebarSubmenu>
          </SidebarItem>
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
      <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
    </Sidebar>
    {children}
  </SidebarPage>
);
