import {
    RadarQuadrant,
    RadarRing,
    RadarEntry,
    TechRadarApi,
    TechRadarLoaderResponse,
    MovedState,
} from '@backstage-community/plugin-tech-radar';

import TurndownService from 'turndown';
import tables from './turndown/rules/tables';

export const radarSourcesURI = 'https://stabackstageprod001.blob.core.windows.net/tech-radar';

export const radarSources: any = {
    'dev': 'Radar_Dev.json',
    'ops': 'Radar_Ops.json',
    'sec': 'Radar_Security.json',
    'test': 'Radar_Test.json',
    'soft': 'Radar_Logiciel.json',
    'comp': 'Radar_Component.json',
    'alm': 'Radar_ALM.json',
};

export const radarRingColor: any = {
    'adopt': '#5BA300',
    'trial': '#009EB0',
    'assess': '#C7BA00',
    'hold': '#E09B96',
};

export type RadarCegidEntryType = {
    name: string;
    ring: string;
    quadrant: string;
    description: string;
    isNew: boolean;
};

export class TechRadarCegidClient implements TechRadarApi {
    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {

        const prepareAndCleanDescription = (description: string) => {
            return description.replace(/<table>(.*?)<\/table>/gis, (_match, p1) => {
                const cleanedTable = p1
                    .replace("<tr style='background-color:#d0d0ff;'><td>link</td>", "<tr>")
                    .replace("</tr><tr style='background-color:#ffffff;'><td>name</td>", "")
                    .replace("</tr><tr style='background-color:#d0d0ff;'><td>ring</td>", "")
                    .replace("</tr><tr style='background-color:#ffffff;'><td>quadrant</td>", "")
                    .replace("</tr><tr style='background-color:#ffffff;'><td>users</td>", "")
                    .replace("</tr><tr style='background-color:#d0d0ff;'><td>users</td>", "")
                    .replace("</tr><tr style='background-color:#ffffff;'><td>status</td>", "")
                    .replace("</tr><tr style='background-color:#d0d0ff;'><td>status</td>", "")
                    .replace("</tr><tr style='background-color:#d0d0ff;'><td>radar</td>", "")
                    .replace("</tr><tr style='background-color:#ffffff;'><td>radar</td>", "")
                    .replace("</tr><tr style='background-color:#ffffff;'><td>teams</td>", "")
                    .replace("</tr><tr style='background-color:#d0d0ff;'><td>isNew</td>", "");
                return `<table><thead><tr><th>link</th><th>name</th><th>ring</th><th>quadrant</th><th>users</th><th>status</th><th>radar</th><th>teams</th><th>isNew</th></tr></thead>${cleanedTable}</table>`;
            });
        };

        const radarSourceFileURI = id ? `${radarSourcesURI}/${radarSources[id]}` : `${radarSourcesURI}/${radarSources['dev']}`;

        const data: Array<RadarCegidEntryType> = await fetch(radarSourceFileURI).then(res => res.json());

        // extracts the quadrants from the data
        const quadrants: Array<RadarQuadrant> = data.reduce((acc, entry) => {
            if (!acc.find(quadrant => quadrant.id === entry.quadrant)) {
                acc.push({
                    id: entry.quadrant,
                    name: entry.quadrant
                });
            }
            return acc;
        }, [] as Array<RadarQuadrant>);

        // extracts the rings from the data
        const rings: Array<RadarRing> = data.reduce((acc, entry) => {
            if (!acc.find(ring => ring.id === entry.ring)) {
                // Fix for the ring name in the data source
                const ringName = entry.ring.toLocaleLowerCase() === "access" ? "Assess" : entry.ring;
                acc.push({
                    id: entry.ring,
                    name: ringName,
                    color: radarRingColor[ringName.toLocaleLowerCase()],
                });
            }
            return acc;
        }, [] as Array<RadarRing>);

        // extracts the entries from the data
        const entries: Array<RadarEntry> = data.map(entry => {

            const descriptionCleaned = prepareAndCleanDescription(entry.description);
            const turndownService = new TurndownService();
            turndownService.use(tables);
            const descriptionMarkdown = turndownService.turndown(descriptionCleaned);

            return {
                id: entry.name,
                title: entry.name,
                description: descriptionMarkdown,
                key: entry.name,
                url: entry.name,
                quadrant: entry.quadrant,
                timeline: [
                    {
                        date: new Date(),
                        ringId: entry.ring,
                        moved: MovedState.NoChange,

                    }
                ],
            }
        });

        // remove duplicates entries
        const uniqueEntries = entries.reduce((acc, entry) => {
            if (!acc.find(e => e.id === entry.id)) {
                acc.push(entry);
            }
            return acc;
        }, [] as Array<RadarEntry>);

        return {
            quadrants: quadrants,
            rings: rings,
            entries: uniqueEntries,
        };
    }
}