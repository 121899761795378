import { AzureMetricsApi, MetricsReport } from './AzureMetrisApiRef';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

/**
 * Provides a client for interacting with the Azure Metrics API.
 */
export class AzureMetricsClient implements AzureMetricsApi {

    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;

    /**
     * Constructs a new instance of the AzureMetricsClient class.
     * @param options - The options to use when constructing the client.
     * @param options.discoveryApi - The discovery API to use for the client.
     * @param options.identityApi - The identity API to use for the client.
     */
    constructor(options: {
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
    }

    /**
     * Retrieves an array of metrics reports for a given component reference.
     * @param options - An object containing the component reference.
     * @returns An array of MetricsReport objects.
     */
    public async getMetricsReports(options: { componentRef?: string }): Promise<Array<MetricsReport>> {
        const urlSegment = `report/${options.componentRef}`;
        const reports = await this.get<Array<MetricsReport>>(urlSegment);
        return reports;
    }

    /**
     * Makes a GET request to the specified path and returns the response as a Promise.
     * @param path - The path to make the GET request to.
     * @returns A Promise that resolves to the response body as type T.
     * @throws {ResponseError} If the response status is not ok.
     */
    private async get<T>(path: string): Promise<T> {
        const baseUrl = `${await this.discoveryApi.getBaseUrl('azure-metrics')}`;
        const url = new URL(`${baseUrl}/${path}`);
        const { token } = await this.identityApi.getCredentials();
        const response = await fetch(url.toString(), {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return response.json() as Promise<T>;
    }

}