import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
  microsoftAuthApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { MySpaceClient, mySpaceApiRef } from './api';

/**
 * The plugin that provides the My Space feature to Backstage.
 */
export const mySpacePlugin = createPlugin({
  id: 'my-space',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: mySpaceApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef, authApi: microsoftAuthApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, identityApi, authApi, fetchApi }) =>
        new MySpaceClient({ discoveryApi, identityApi, authApi, fetchApi }),
    }),
  ]
});

/**
 * A routable extension that provides the MySpace page component.
 */
export const MySpacePage = mySpacePlugin.provide(
  createRoutableExtension({
    name: 'MySpacePage',
    component: () =>
      import('./components/MySpaceContentPage').then(m => m.MySpaceContentPage),
    mountPoint: rootRouteRef,
  }),
);
